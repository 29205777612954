import React from 'react';

import AGLogo from '../assets/introduction/web_mt_misc_logo_ag.svg';

import leaderboardIcon from '../assets/newTournament/social_leaderboard.webp';

import step2Sc from '../assets/micardTournament/step_2_stw_sc.png';
import step2En from '../assets/micardTournament/step_2_stw_en.png';
import step2Tc from '../assets/micardTournament/step_2_stw_tw.png';
import step3En from '../assets/micardTournament/step_3_spin_to_win_en.png';
import step3Tc from '../assets/micardTournament/step_3_spin_to_win_tw.png';
import step3Sc from '../assets/micardTournament/step_3_spin_to_win_sc.png';

import { type IIntroduction } from '../interfaces';

import '../styles/Introduction.scss';
import { languageCodes } from '../languages/languages';

export const Introduction = ({ t, i18n, isChinese }: IIntroduction):
JSX.Element => {
  const isTradChinese =
    i18n?.resolvedLanguage === languageCodes.chineseTraditional;

  const checkStep2 = (): string => {
    if (isChinese) {
      if (isTradChinese) return step2Tc;
      return step2Sc;
    }

    return step2En;
  };
  const checkStep3 = (): string => {
    if (isChinese) {
      if (isTradChinese) return step3Tc;
      return step3Sc;
    }

    return step3En;
  };
  return (
    <section className="introduction-container" id="eventOverview">
      <div className="intro-title">
        <img src={leaderboardIcon} height="30px" width="30px" alt="leaderboard icon"/>
        <h2 style={{ marginTop: '26px' }}>{t('eventOverview')}</h2>
      </div>
      <p>{t('eventOverviewDetails')}</p>
      <div className="how-to-enter-container">
        <section className="how-to-take-part" id="howToParticipate">
          <h2 style={{ marginTop: '0px' }}>{t('howToParticipate')}</h2>
          <section className="steps-container">
            <div>
              <aside>
                <p>{t('step') + ' '}</p>
                <strong className="card-header">1</strong>
              </aside>
              <div className="img-container">
                <img src={AGLogo} alt="AG Logo"/>
              </div>
              <p className="step-instructions" style={{ marginTop: '40px' }}>{t('howTo1')}</p>
            </div>
            <div>
              <aside>
                <p>{t('step') + ' '}</p><strong className="card-header">2</strong>
              </aside>
              <div className="img-container" style={{ padding: '0px' }}>
                <img src={checkStep2()} alt="step 2 logo" width="200px" height="auto"/>
              </div>
              <p className="step-instructions" style={{ marginTop: '40px' }}>{t('howTo2')}</p>
            </div>
            <div>
              <aside>
                <p>{t('step') + ' '}</p><strong className="card-header">3</strong>
              </aside>
              <section style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '32px' }}>
                <img src={checkStep3()} alt="step 3 logo" width="250px" height="auto"/>
              </section>
              <p className="step-instructions" style={{ marginTop: '23px' }}>{t('howTo3')}</p>
            </div>
          </section>
        </section>
      </div>
    </section>);
};
