import React, { useEffect, useState } from 'react';
import { ParallaxBanner, ParallaxBannerLayer } from 'react-scroll-parallax';
import { AnimatePresence, motion } from 'framer-motion';

import girlFullBody from '../assets/micardTournament/girl_full_body.png';
import coinsLeft from '../assets/micardTournament/coin_left.webp';
import coinsRight from '../assets/micardTournament/coin_right.webp';
import spinner from '../assets/micardTournament/btn_entry_hans.webp';
import tournamentLogoSc from '../assets/micardTournament/micard_logo_hans.webp';
import tournamentLogoEn from '../assets/micardTournament/micard_logo_en.webp';
import tournamentLogoTc from '../assets/micardTournament/logo_tc.webp';

import { type IBanner } from '../interfaces';

import { LoadingBanner } from './LoadingBanner';

import '../styles/Banner.scss';
import { languageCodes } from '../languages/languages';

export default function Banner (
  { t, isChinese, i18n, isMobileView }: IBanner): JSX.Element {
  const [loading, setLoading] =
    useState({
      girl: false,
      coinsLeft: false,
      coinsRight: false,
      spinner: false,
      tournamentLogo: false,
      tournamentLogoEN: false
    });

  const isTradChinese =
    i18n.resolvedLanguage === languageCodes.chineseTraditional;

  const checkLogo = (): string => {
    if (isChinese) {
      if (isTradChinese) return tournamentLogoTc;
      return tournamentLogoSc;
    }

    return tournamentLogoEn;
  };

  const TRANSITION_DURATION: number = 0.5;

  useEffect(() => {
    const girlImage = new Image();
    girlImage.onload = () => { setLoading(prev => ({ ...prev, girl: true })); };
    girlImage.src = girlFullBody;

    const coinsLeftImage = new Image();
    coinsLeftImage.onload = () => {
      setLoading(prev => ({ ...prev, coinsLeft: true }));
    };
    coinsLeftImage.src = coinsLeft;

    const coinsRightImage = new Image();
    coinsRightImage.onload = () => {
      setLoading(prev => ({ ...prev, coinsRight: true }));
    };
    coinsRightImage.src = coinsRight;

    const spinnerImage = new Image();
    spinnerImage.onload = () => {
      setLoading(prev => ({ ...prev, spinner: true }));
    };
    spinnerImage.src = spinner;

    const logoImage = new Image();
    logoImage.onload = () => {
      setLoading(prev => ({ ...prev, tournamentLogo: true }));
    };
    logoImage.src = tournamentLogoSc;

    const logoImageEn = new Image();
    logoImageEn.onload = () => {
      setLoading(prev => ({ ...prev, tournamentLogoEN: true }));
    };
    logoImageEn.src = tournamentLogoEn;
  }, []);

  if (Object.values(loading).every(el => el)) {
    return (
      <div className="banner">
        <ParallaxBanner className="parallax-banner">
          <>
            <ParallaxBannerLayer
              speed={-10}
            >
              <div className="spinner-container">
                <div className="spinner">
                  <div className="blur-2-top"/>
                  <div className="blur-2" />
                </div>
              </div>
            </ParallaxBannerLayer>
            <ParallaxBannerLayer
              image={coinsLeft}
              className="coins-left"
              speed={-9}
            />
            <ParallaxBannerLayer
              image={coinsRight}
              className="coins-right"
              speed={-8}
            />
            <ParallaxBannerLayer
              speed={-7}
            >
              <div className={isMobileView ? 'girl-mobile' : 'girl' }>
                <div className="blur"></div>
              </div>
            </ParallaxBannerLayer>
            <ParallaxBannerLayer
              image={checkLogo()}
              className="tournament-logo"
              speed={-6}>
            </ParallaxBannerLayer>
            <AnimatePresence mode="wait">
              <motion.div
                initial={{ opacity: 0, backfaceVisibility: 'hidden' }}
                animate={{ opacity: 1 }}
                transition={{ duration: TRANSITION_DURATION }}
                exit={{ opacity: 0 }}
              >
                <ParallaxBannerLayer
                  speed={10}>
                  <div className="logo-text">
                    <div className='total-prizes-container'>
                      <p className="spin-for-fortune-title">
                        {t('stablePlatform')}
                      </p>
                      {isMobileView &&
                      <div style={{ marginTop: '4px' }}>
                        <p className="prize-text" style={{ fontSize: '40px' }}>
                          {t('fiveMil')}
                          {!isChinese && ' '}
                        </p>
                      </div>}
                      <div className="prize-text-container">
                        {!isMobileView &&
                        <div>
                          <p className="prize-text">
                            {t('fiveMil')}
                            {!isChinese && ' '}
                          </p>
                        </div>}
                        <p className="total-prize-1" style={{ marginTop: '0px' }}>
                          {t('fiveMilReward')}
                        </p>
                      </div>
                      <p className="event-time">
                        {t('eventTimeDetails')}
                      </p>
                    </div>
                  </div>
                </ParallaxBannerLayer>
              </motion.div>
            </AnimatePresence>
          </>
        </ParallaxBanner>
      </div>
    );
  }

  return <LoadingBanner />;
};
