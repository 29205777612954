import React from 'react';
import AppleIcon from '@mui/icons-material/Apple';

import { ReactComponent as AndroidIcon } from '../assets/footer/android2.svg';
import { ReactComponent as DevicesIcon } from '../assets/footer/console.svg';
import { ReactComponent as HtmlIcon } from '../assets/footer/html5.svg';
import { ReactComponent as WindowsIcon } from '../assets/footer/windows-icon.svg';
import { type IFooter } from '../interfaces';

import '../styles/Footer.scss';

export const Footer = ({ isMobileView }: IFooter): JSX.Element => {
  let appleIconStyle = { height: '50px', width: '45px', color: 'white' };
  let androidIconStyle = { height: '50px', width: '50px', minWidth: '50px' };
  let windowsIconStyle = { height: '50px', width: '46px', minWidth: '46px' };
  let htmlIconStyle = { height: '50px', width: '47px', minWidth: '47px' };
  let devicesIconStyle = { height: '60px', width: '98px', minWidth: '98px' };

  if (isMobileView) {
    appleIconStyle = { height: '40px', width: '36px', color: 'white' };
    androidIconStyle = { height: '40px', width: '40px', minWidth: '40px' };
    windowsIconStyle = { height: '40px', width: '37px', minWidth: '37px' };
    htmlIconStyle = { height: '40px', width: '38px', minWidth: '38px' };
    devicesIconStyle = { height: '48px', width: '79px', minWidth: '79px' };
  }

  return (
    <div className="footer-container">
      <div className="icons-container">
        <AppleIcon style={appleIconStyle}/>
        <AndroidIcon style={androidIconStyle}/>
        <WindowsIcon style={windowsIconStyle}/>
        <HtmlIcon style={htmlIconStyle}/>
        <DevicesIcon style={devicesIconStyle}/>
      </div>
      <footer>
        <p>ASIA GAMING. 版權所有&#9702; &copy; COPYRIGHT 2025</p>
      </footer>
    </div>);
};
